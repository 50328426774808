.slider-container {
	display: flex;
	flex-direction: column;
	padding: 8px 0px;
}

.slider-title {
	font-size: 0.75rem;
	line-height: 120%;

	flex: 0 0 fit-content;
	margin-bottom: 4px;
}

.slider-title.hidden {
	visibility: hidden;
}

.slider-content {
	display: flex;

	flex: 1 0 fit-content;

	overflow-x: auto;
}

.slider-content::after {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 90%, rgba(255,255,255,1) 100%);;
}