.tips-layout-header {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	min-height: 48px;
	height: fit-content;
	width: 100%;
}

.tips-layout-header-layout-helper {
	width: 48px;
}
