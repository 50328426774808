.tips-layout {
	display: flex;
	flex-direction: column;

	color: #292827;

	height: 100%;
	width: 100%;

	max-width: 700px;

	background-color: #ffffff;
}

.tips-layout-navigation {
	display: flex;
	align-items: center;

	flex: 0 0 fit-content;
}

.tips-layout-title {
	flex: 0 0 auto;

	color: #0D696C;
	font-size: 1rem;
	font-weight: normal;

	margin-top: 0px;
	margin-bottom: 16px;

	padding: 0px 16px;
}

.tips-layout-content {
	flex: 1 0 0;
	overflow: hidden;
}

.tips-layout-progression {
	flex: 0 0 4px;
}

.tips-layout-action {
	display: flex;
	justify-content: center;
	align-items: center;

	flex: 0 0 60px;
}

/*
@media (min-width: 700px) {
	.tips-layout {
		padding: 8px;
		border-radius: 16px;

		max-height: 400px;
	}

	.tips-layout-progression {
		padding: 0px 16px;
	}

	.tips-layout-action {
		padding-top: 8px;

		flex: 0 0 68px;
	}
}
*/