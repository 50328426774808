.coaching-navigation {
	display: flex;
	flex-direction: column;

	height: 100%;
	width: 100%;
}

@media (min-width: 600px){
	.coaching-navigation {
		padding: unset;
	}
}

.coaching-navigation-with-objective {
	border-bottom-left-radius: 50% 16px;
	border-bottom-right-radius: 50% 16px;

	transition: all 0.4s;
}

@media (min-width: 600px){
	.coaching-navigation-with-objective {
		padding-bottom: 8px;
	}
}

.coaching-navigation-progress-container {
	flex: 0 0 8px;
}

.coaching-navigation-actions-container {
	flex: 1 0 0;

	display: flex;
	justify-content: space-between;
	align-items: center;
}

.coaching-navigation-actions-container > svg {
	fill:#292827;
}

@media (max-width: 600px) {
	.coaching-navigation-actions-container > svg {
	fill:#292827;
	}
}

.coaching-navigation-objective-description {
	color: #292827;
	font-size: 0.8rem;
	margin: auto;
	max-height: calc(1.4rem + 24px);
	width: 70%;

	text-align: center;

	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

@media (max-width: 600px) {
	.coaching-navigation-objective-description {
		color: #292827;
	}
}