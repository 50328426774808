.slider-editor-modal {
	display: flex;
	justify-content: center;
	align-items: center;

	height: 100%;
	width: 100%;
}

.slider-editor-layout {
	display: flex;
	flex-direction: column;

	padding: 16px;

	height: 100%;
	max-height: 100%;
	width: 100%;
	max-width: 700px;

	overflow: hidden;
}

.slider-editor-title {
	display: flex;
	justify-content: space-between;
	align-items: center;

	flex: 0 0 fit-content;

	background-color: white;
	border-radius: 4px;

	padding: 6px 12px;
	padding-right: 6px;
}

.slider-editor-content {
	display: flex;
	flex-direction: column;

	flex: 1 0 0;

	margin-top: 8px;

	overflow: hidden;
}

.slider-editor-content-list {
	display: flex;
	flex-direction: column;

	flex: 0 0 fit-content;

	max-height: 100%;

	overflow-y: auto;

	border-radius: 4px;
	background-color: white;
}

@media (min-width: 700px) {
	.slider-editor-layout {
		padding: 16px;
		border-radius: 16px;

		min-height: 500px;
		max-height: 100%;
	}
}
