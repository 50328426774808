.tips-instruction {
	display: flex;
	flex-direction: column;
	
	height: 100%;
}

.tips-instruction-title {
	flex: 0 0 fit-content;
}

.tips-instruction-page-container {
	flex: 1 0 0;
	overflow-y: auto;
}

.tips-instruction-stepper {
	display: flex;
	justify-content: center;
	align-items: center;

	flex: 0 0 fit-content;
}

.tips-instruction-stepper-placeholder {
	flex: 0 0 8px;
	padding-bottom: 8px;
}

.tips-instruction-confirm-button {
	display: flex;

	flex: 0 0 fit-content;
	padding: 16px;

	justify-content: center;
}
