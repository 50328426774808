
.coaching-situation-navigation-container {
	flex: 0 0 fit-content;
}

@media (max-width: 600px){
	.coaching-situation-navigation-container {
		padding: 32px 16px 8px;
	}
}

.coaching-situation-container {
	display: flex;
	flex-direction: column;

	flex: 1 0 0;

	padding: 12px 16px 24px 16px;
	overflow: hidden;

}

.coaching-situation-content {
	display: flex;
	flex-direction: column;

	flex: 1 0 0;
	overflow: hidden;
}

.coaching-situation-tips {
	flex: 0 0 fit-content;
}

.coaching-situation-confirm {
	flex: 0 0 fit-content;
}