.confirmation-button {
	display: flex;
	justify-content: center;
	align-items: center;

	height: 0px;
	transition: all 0.25s;

	overflow: hidden;
}

.confirmation-button-shown {
	height: 48.5px;
	transition: all 0.4s;

	overflow: hidden;
}
