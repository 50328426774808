.slider-editor-item {
	display: flex;
	flex-direction: row;

	padding: 12px;
}

.slider-editor-item-text {
	display: flex;
	align-items: center;

	flex: 1 0 0;
}

.slider-editor-item-text-fullwidth {
	display: flex;
	align-items: center;

	flex: 1 0 0;
}

.slider-editor-item-actions {
	display: flex;
	align-items: center;

	flex: 0 0 fit-content;
}

.slider-editor-item-actions-root {
	position: relative;
	width: 80px;
}

.slider-editor-item-actions-buttons {
	position: absolute;

	transform: translateX(8px) translateY(-50%);
}
