.slider-container {
	display: flex;
	flex-direction: column;
	padding: 8px 0px;
}

.slider-title {
	font-size: 0.75rem;
	line-height: 120%;

	flex: 0 0 fit-content;
	margin-bottom: 4px;
}
.slider-content {
	display: flex;

	flex: 1 0 fit-content;

	overflow-x: auto;
}

.slider-tile {
	background-color: #ffffff;
	border-radius: 4px;
	padding: 12px;
	margin-top: 1px;
	margin-bottom: 1px;
	margin-right: 8px;

	height: 60px;
	min-width: 22%;
	max-width: 300px;
}

@media (max-width: 599px) {
	.slider-tile {
		min-width: 42%;
	}
}

.slider-text {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;

	font-size: 0.75rem;
	line-height: 120%;

	height: 100%;
	max-width: 100%;
}

.slider-edition-icon-anchor {
	position: relative;
}

.slider-edition-icon {
	position: absolute;

	right: 0;
	bottom: 0;

	transform: translateX(8px) translateY(8px);
}
