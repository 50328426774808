.tips-inspiration {
	display: flex;
	flex-direction: column;

	height: 100%;
}

.tips-inspiration-content {
	flex: 1 0 0;
	overflow-y: auto;
}

.tips-inspiration-non-exhaustive-mention {
	flex: 0 0 fit-content;

	text-align: center;
	font-style: italic;
}

.tips-inspiration-quit {
	display: flex;
	justify-content: center;

	flex: 0 0 fit-content;

	padding-top: 16px;
	padding-bottom: 16px;
}
