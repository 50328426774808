.tips-instruction-page {
	display: flex;
	flex-direction: column;
	width: 100%;
}

@media (max-width: 568px) {
	.tips-instruction-page{
		padding: 16px;
	}
}

.tips-instruction-page-message-container {
	display: flex;
	flex-direction: column;

	flex: 0 0 fit-content;

	margin: 0px;
	margin-bottom: 16px;
}

.tips-instruction-page-message {
	width: 100%;

	font-size: 1rem;
}

.main > div > div {
	color: #0D696C;
	font-size: 1.125rem;
	line-height: 125%;
}
