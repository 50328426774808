.tips-button {
	display: flex;

	cursor: pointer;

	padding: 16px;

	height: 108px;
	width: 100%;

	background: #ffffff;
	border-radius: 4px;
	margin-bottom: 0px;

	transition: all 0.4s;
}

.tips-content {
	display: flex;
	flex-direction: column;
	justify-content: center;

	overflow: hidden;

	flex: 1 0 0;

	transition: all 0.4s;
}

.tips-content-title {
	flex-basis: 23px;
	color: #292827;
	/*color: #102336;*/
	font-size: 19px;
	min-height: 24px;
	line-height: 120%;
	margin-bottom: 8px;

	opacity: 1;
	overflow: hidden;

	transition: all 0.4s;
}

.tips-content-text {
	flex-basis: 60px;

	color: #292827;
	/*color: #102336;*/
	font-size: 12px;
	line-height: 120%;

	opacity: 1;
	height: fit-content;

	transition: all 0.4s;
}

.tips-title-alternative {
	display: flex;
	flex-direction: column;
	justify-content: center;

	flex: 0 0 0;

	overflow: hidden;

	transition: all 0.4s;
}

.tips-title-alternative-text {
	flex-basis: 16px;
	color: #292827;
	font-size: 14px;
	line-height: 120%;
	margin-bottom: 0px;

	opacity: 0;

	transition: all 0.4s;
}

.tips-coach {
	display: flex;

	flex: 0 0 fit-content;

	justify-content: center;
	align-items: center;

	transition: all 0.4s;
}

.tips-button-coach-image-container {
	height: 76px;
	width: 76px;

	transition: all 0.4s;
}

.tips-coach-image {
	height: 100%;
	width: 100%;

	max-height: 100%;
	max-width: 100%;
}

/* Minimized */

.tips-button-minimized {
	display: flex;

	cursor: pointer;

	padding: 12px 16px;
	height: auto;

	margin-bottom: 8px;

	transition: all 0.4s;
}

.tips-button-minimized .tips-content {
	flex: 0 0 0;

	transition: all 0.4s;
}

.tips-button-minimized .tips-content .tips-content-title {
	opacity: 0;

	transition: all 0.4s;
}

.tips-button-minimized .tips-title-alternative {
	flex: 1 0 0;
	transition: all 0.4s;
}

.tips-button-minimized .tips-title-alternative .tips-title-alternative-text {
	margin-bottom: 0px;
	opacity: 1;

	transition: all 0.4s;
}

.tips-button-minimized .tips-content .tips-content-text {
	flex-basis: 0px;

	color: #292827;
	font-size: 12px;
	line-height: 120%;

	height: 0px;

	opacity: 0;

	transition: all 0.4s;
}

.tips-button-minimized .tips-coach .tips-button-coach-image-container {
	height: 32px;
	width: 32px;

	transition: all 0.4s;
}
