.home-container {
	display: flex;
	flex-direction: column;

	height: 100%;
	width: 100%;
}

.home-coach-container {
	display: flex;
	justify-content: center;
	align-items: center;

	flex: 0 0 fit-content;

	padding-top: 32px;
	padding-bottom: 16px;
}

.home-coach-image-container {
	height: 128px;
	width: 140px;

	border-radius: 50%;
	overflow: hidden;
}

.home-coach-image {
	height: 100%;
	width: 100%;

	max-height: 100%;
	max-width: 100%;
}

.home-welcome-message-container {
	flex: 0 0 fit-content;

	margin: 0;
	padding: 0px 16px;

	font-size: 2.8rem;
}

.home-menu-container {
	display: flex;
	flex-direction: column;

	flex: 1 0 0;
	gap: 16px;

	padding: 16px;
}

.home-video-button {
	display: flex;
	flex-direction: column;

	justify-content: flex-end;
	align-items: flex-start;

	flex: 1 0 0;

	height: 50%;
	width: 100%;

	border-radius: 4px;
	background: linear-gradient(248.5deg, #88ebfc 0%, #1393a8 106.65%);
	/*background-color: #dcf7f7;*/

	padding: 16px;

	cursor: pointer;
}

.home-menu-button {
	display: flex;
	flex-direction: column;

	justify-content: flex-end;
	align-items: flex-start;

	flex: 1 0 0;

	height: 50%;
	width: 100%;

	border-radius: 4px;
	background: linear-gradient(248.5deg, #88ebfc 0%, #1393a8 106.65%);
	/*background-color: #dcf7f7;*/

	padding: 16px;

	cursor: pointer;

	transition: all 1s;
}
.home-menu-button-hidden {
	flex: 0 0 0;
	padding: 0px 16px;
	overflow: hidden;
}

.home-menu-button-title {
	/*color: #10895a;*/
	color: white;

	font-family: 'Amaranth';
	font-weight: 700;

	font-size: 1rem;
}

.home-menu-button-title-details {
	/*color: #292827;*/
	color: white;

	font-family: 'Amaranth';
	font-weight: 400;

	font-size: 1rem;
}

.home-menu-video-close-button {
	width: fit-content;
	margin-left: auto;
}

.home-menu-video {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);

	max-height: 100%;
	max-width: 100%;

	background-color: #222222;
}

.home-menu-bottom-navigation {
	flex: 0 0 auto;
}
